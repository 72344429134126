import React, { useEffect } from "react";

import styles from "../styles.module.scss";

import { ReactComponent as CardIcon } from "../../../../assets/icons/credit-card-upload.svg";
import { motion } from "framer-motion";
import { motionButtonProps } from "../../../../utils/styles/motionSettings";
import classNames from "classnames";
import useGoNextOrder from "../../../../utils/hooks/useGoNextOrder";
import ErrorParser from "../../../../components/shared/ErrorParser/ErrorParser";
import Block from "../../../../components/shared/Block/Block";

export default function NextOrderButton() {
  const { getNextOrder, state } = useGoNextOrder({});
  const error: any = state?.error;

  return (
    <Block>
      {error && error?.status !== 404 && (
        <ErrorParser
          error={error}
          translates={{
            keys: [
              {
                key: "base",
                translate: "Ошибка",
              },
            ],
            values: [
              {
                key: "token is suspended",
                translate: "Токен приостановлен",
              },
            ],
          }}
        />
      )}

      <motion.button
        {...motionButtonProps}
        className={classNames(styles.paper, styles.newOrder)}
        onClick={getNextOrder}
      >
        <CardIcon /> Перейти к новой заявке
      </motion.button>
    </Block>
  );
}
