import React from "react";

import styles from "./styles.module.scss";

import { ReactComponent as CancelIcon } from "../../../../assets/icons/x-close.svg";
import { motion } from "framer-motion";
import { motionButtonProps } from "../../../../utils/styles/motionSettings";
import classNames from "classnames";

export default function CancelBlock({ onCancel }: { onCancel: () => void }) {
  return (
    <section className={styles.cancelContainer}>
      <h3 className={styles.cancelTitle}>Проблемы с оплатой?</h3>
      <motion.button
        onClick={onCancel}
        {...motionButtonProps}
        className={classNames(styles.paper, styles.cancel)}
      >
        <CancelIcon /> Отменить выплату
      </motion.button>
    </section>
  );
}
