import classNames from 'classnames';

import type { LoadMoreProps } from './LoadMore.types';

import s from './LoadMore.module.scss';

import { ReactComponent as PlusCircleIcon } from "@/assets/icons/plus-circle.svg";

import React from "react";

function LoadMore({ className }: LoadMoreProps) {

    return <div className={classNames(s.load, className)}>
        <div className={s.interactive}>
            <PlusCircleIcon className={s.interactive__image}/>
        </div>
        <div className={s.info}>
            <p className={s.info__text}>
               Добавить
            </p>
        </div>
    </div>;
}

export default LoadMore;
