import type { FileInputPlaceholderProps } from './FileInputPlaceholder.types';

import { ReactComponent as FileIcon } from "@/assets/icons/file.svg";
import s from './FileInputPlaceholder.module.scss';

function FileInputPlaceholder({text = 'Кликните, чтобы загрузить файл'}: FileInputPlaceholderProps) {
    return <div className={s.placeholder}>
        <FileIcon className={s.placeholder__icon} />
        <p className={s.placeholder__text}>{text}</p>
    </div>;
}

export default FileInputPlaceholder;
