const orderPageText = {
  orderPage: {
    timer: {
      description: "Время на оплату заявки:",
      timeIsOver: "Время вышло",
      min: "мин",
      sec: "сек",
    },
    modalEmptyNext: {
      title: "Нет активных заявок",
      description:
        "Система не смогла подобрать заявку под ваши параметры аккаунта.",
      params: "Параметры аккаунта",
      close: "Закрыть",
    },
    modalCancel: {
      title: "Отменить заявку",
      subTitle: "Выберите причину отмены заявки на выплату",
      blocked: {
        title: "Карта получателя заблокирована",
      },
      troubles: {
        title: "Проблемы с оплатой",
        notice:
          "Текущая заявка на выплату перейдет к другому исполнителю, а ваш токен будет отключён",
      },
      submit: "Отменить заявку",
      errors: {
        verification: "Нельзя отменить заявку на проверке",
      },
    },
    modalMakePaid: {
      errors: {
        id: {
          title: "Ошибка",
          description: "Не найден id",
        },
      },
      title: "Не получили чек об оплате?",
      description:
        "Нажмите кнопку <b>Загрузить позднее</b> и тогда MassMo будет считать ваш платеж временно выполненным.",
      warning:
        "В случае подтверждения, вам необходимо приложить чек в течение 12 часов {{date}}, иначе мы отменим выплату",
      submit: "Загрузить позднее",
    },
    reservesBlock: {
      collapse: "Свернуть",
      expand: "Все резервы",
    },
    reservesOver: {
      title: "Резервы исчерпаны",
      description:
        "Вы не можете оплатить заявку, <br /> так как у вас недостаточно резервов",
      button: "Изменить резервы",
    },
    notFound: {
      title: "У вас нет активной заявки на выплату",
      description:
        'Нажмите "получить заявку на выплату", <br />для запроса новой заявки',
      nextTranslates: {
        keys: {
          base: "Ошибка",
        },
        values: {
          suspended: "Токен приостановлен",
        },
      },
      button: "Получить заявку на выплату",
    },
    error: {
      title: "Ошибка",
    },
    tokenDisabled: {
      title: "Токен отключен",
      description: "Вы не получаете заявки на выплаты",
      button: "Управление аккаунтом",
    },
    processing: {
      title: "Заявка ожидает оплаты",
    },
    paid: {
      title: "Заявка ожидает загрузки чека",
      description: "Загрузите чек об оплате до",
    },
    verification: {
      title: "Проверяем ваш платеж",
      description: "Система сканирует чек об операции",
    },
    completed: {
      title: "Заявка оплачена",
      description: "Платеж прошел проверку",
    },
    expired: {
      title: "Заявка не была оплачена",
      description: "Истекло время на оплату",
    },
    verification_failed: {
      title: "Платеж не прошел проверку",
      description: "Проверьте корректность чека <br /> и реквизиты получателя",
    },
    manual_verification: {
      title: "Проверка администрацией",
      description: "Платеж на проверке",
    },
    canceled: {
      title: "Заявка отменена",
      description: "Вы отменили данную заявку",
    },
    default: {
      title: "Нераспознан статус заявки",
      description: "Ошибка, не удалось определить статус заявки",
    },
  },
};

export default orderPageText;
