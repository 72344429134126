import React, { useState } from "react";

import styles from "./styles.module.scss";

import ModalBase, { IModalBase } from "../ModalBase/ModalBase";
import { useGetBanksListQuery } from "../../../store/payoutLimits/payoutLimits.api";
import { ReactComponent as BankIcon } from "@/assets/icons/bank.svg";
import { ReactComponent as SearchIcon } from "@/assets/icons/search-md.svg";
import Input from "@/components/controls/Input/Input";

interface IModalSelectBank extends IModalBase {
  selectedBankName: string;
  setSelectedBankName(val: string): void;
}

export default function ModalSelectBank(props: IModalSelectBank) {
  const { selectedBankName, setSelectedBankName, ...rest } = props;
  const [search, setSearch] = useState("");

  const { data: banksData, isLoading: isBanksLoading } = useGetBanksListQuery({
    regionId: 1,
  });

  let bank;
  if (banksData?.data) {
    bank = banksData.data.find((i) => i.alias === "");
  }

  return (
    <ModalBase {...rest}>
      <div className={styles.container}>
        <div className={styles.head}>
          <h2>Банк для оплаты</h2>
          <p>Выберите банк для оплаты заявки</p>
        </div>
        <Input
          leftElement={<SearchIcon />}
          containerStyle={styles.search}
          inputAttributes={{
            placeholder: "Поиск",
            value: search,
            onChange: (e: any) => setSearch(e.target.value),
          }}
        />
        <div className={styles.selectContainer}>
          <div className={styles.selectList}>
            {banksData?.data
              .filter(
                (i) =>
                  i.name.toLowerCase().includes(search.toLowerCase()) ||
                  i.alias
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase())
              )
              .map((i) => (
                <button
                  onClick={() => {
                    setSelectedBankName(i.alias);
                    rest.onClose();
                  }}
                  className={styles.selectItem}
                  key={i.alias}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="19"
                      height="19"
                      rx="9.5"
                      fill="#F9FAFB"
                      stroke="#EEF2F9"
                    />
                    {i.alias === selectedBankName && (
                      <rect
                        x="4"
                        y="4"
                        width="12"
                        height="12"
                        rx="6"
                        fill="#0052FF"
                      />
                    )}
                  </svg>
                  <div className={styles.itemBank}>
                    <div>
                      {i.logo ? (
                        <img
                          className={styles.bankLogo}
                          src={i?.logo}
                          alt="logo"
                        />
                      ) : (
                        <BankIcon />
                      )}
                    </div>
                    <p>{i.name}</p>
                  </div>
                </button>
              ))}
          </div>
        </div>
      </div>
    </ModalBase>
  );
}
