import React, { useState } from "react";

import styles from "../styles.module.scss";

import classNames from "classnames";
import Copy from "../../../../components/shared/Copy/Copy";
import { ReactComponent as SbpIcon } from "../../../../assets/icons/sbp.svg";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye.svg";
import { ReactComponent as BankIcon } from "../../../../assets/icons/bank-blue.svg";
import { ReactComponent as EyeOffIcon } from "../../../../assets/icons/eye-off.svg";
import { ReactComponent as UserIcon } from "../../../../assets/icons/user-circle.svg";
import { paymentColorFullIcons } from "../../../../utils/constants/paymentColorFulIcons";
import { parsePhoneNumber } from "libphonenumber-js";
import { formatCardNumber } from "../../../../utils/helpers/formatCardNumber";
import { motion } from "framer-motion";
import { motionButtonProps } from "../../../../utils/styles/motionSettings";
import { IOrderItem } from "../../../../store/orders/orders.types";

export default function RequisiteBlock({
  bank,
  data,
  isProcessing,
}: {
  bank: { name?: string; logo?: string };
  data: IOrderItem;
  isProcessing: boolean;
}) {
  const [visible, setVisible] = useState(false);
  function getParsedRequisites(data: IOrderItem) {
    let requisites = data.requisites;
    switch (data.requisites_type) {
      case "phone_number": {
        let phoneNumber = parsePhoneNumber(
          data.requisites
        ).formatInternational();

        if (!isProcessing && !visible && phoneNumber) {
          phoneNumber = phoneNumber.slice(0, 3) + "***" + phoneNumber.slice(6);
          phoneNumber = phoneNumber.slice(0, 8) + "***" + phoneNumber.slice(11);
        }

        return phoneNumber;
      }
      case "card_number": {
        let cardNumber;
        cardNumber = formatCardNumber(data.requisites);

        if (!isProcessing && !visible && cardNumber) {
          cardNumber =
            cardNumber.slice(0, 5) + "**** ****" + cardNumber.slice(14);
        }
        return cardNumber;
      }
      default:
        return requisites;
    }
  }

  function getIcon(data: IOrderItem) {
    if (data.sbp) {
      return <SbpIcon />;
    }
    if (data.card_brand && data.requisites_type === "card_number") {
      return paymentColorFullIcons[
        data.card_brand as keyof typeof paymentColorFullIcons
      ];
    }
    switch (data.requisites_type) {
      default:
        return <BankIcon />;
    }
  }

  return (
    <section className={styles.requisiteWrapper}>
      <div className={classNames(styles.requisite, styles.paper)}>
        <div className={styles.bank}>
          <div className={styles.bankLogo}>
            <img src={bank?.logo} alt="logo" />
          </div>
          <div className={styles.bankInfo}>
            <div className={styles.bankName}>
              {bank?.name || data.bank_name || "Нет информации о банке"}
            </div>
          </div>
        </div>
        <div className={styles.cardNumber}>
          <div className={styles.payment}>{getIcon(data)}</div>{" "}
          {getParsedRequisites(data)}
          <div className={styles.copy}>
            {isProcessing ? (
              <Copy value={data.requisites} />
            ) : (
              <motion.button
                className={styles.showButton}
                onClick={() => setVisible(!visible)}
                {...motionButtonProps}
              >
                {visible ? <EyeOffIcon /> : <EyeIcon />}
              </motion.button>
            )}
          </div>
        </div>
        {data.fio && (
          <div className={styles.fio}>
            <div className={styles.fioIcon}>
              <UserIcon />
            </div>
            <div className={styles.fioText}>{data.fio}</div>

            <div className={styles.copy}>
              <Copy value={data.fio || ""} />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
