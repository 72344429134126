import React, { useState } from "react";

import styles from "./styles.module.scss";
import Text from "../../../../components/typography/Text/Text";
import Button from "../../../../components/controls/Button/Button";
import ModalConfirm from "../../../../components/modals/ModalConfirm/ModalConfirm";
import Block from "../../../../components/shared/Block/Block";
import { IOrderItem } from "../../../../store/orders/orders.types";
import { useManualVerificationMutation } from "../../../../store/orders/orders.api";

export default function VerificationFailedBlock({
  data,
}: {
  data: IOrderItem;
}) {
  const [manualVerification, { isLoading, error }] =
    useManualVerificationMutation();
  const [isOpen, setIsOpen] = useState(false);

  async function onConfirm() {
    try {
      await manualVerification({ id: data.id }).unwrap();

      setIsOpen(false);
    } catch (error) {}
  }
  return (
    <>
      <ModalConfirm
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        isConfirmLoading={isLoading}
        error={error}
        title="Отправить на проверку?"
        subTitle={
          <Block>
            <p>
              Вы уверены, что хотите отправить эту заявку на проверку
              администрацией?
            </p>
            <p>
              Подтверждайте действие только в случае, если вы уверены, что
              система некорректно считала вашу справку.
            </p>
            <p>
              До окончания ручной проверки выплаты,
              <Text type="accent">
                <b> вы не получите новые заявки для откупа</b>.
              </Text>{" "}
            </p>
          </Block>
        }
        onConfirm={onConfirm}
      />
      <div className={styles.wrapper}>
        <Text type="danger">
          {JSON.stringify(
            data?.verification_result?.message
              ? data.verification_result.message
              : data.verification_result
          )}
        </Text>
        <div className={styles.buttonContainer}>
          <Button withAnimation onClick={() => setIsOpen(true)}>
            Отправить на проверку администрацией
          </Button>
        </div>
      </div>
    </>
  );
}
