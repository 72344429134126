import React from "react";

import styles from "./styles.module.scss";

import { IOrderItem } from "../../../store/orders/orders.types";
import { useMakePaidMutation } from "../../../store/orders/orders.api";
import ModalBase, { IModalBase } from "../ModalBase/ModalBase";
import dayjs from "dayjs";
import Button from "../../controls/Button/Button";
import showToast from "../../../utils/helpers/toast/showToast";
import Text from "../../typography/Text/Text";
import { useTranslation } from "react-i18next";

interface IModalMakePaid extends IModalBase {
  data?: IOrderItem;
}

export default function ModalMakePaid({ data, ...rest }: IModalMakePaid) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "orderPage.modalMakePaid",
  });
  const [makePaid, { isLoading, error }] = useMakePaidMutation();
  async function onSubmit() {
    if (!data?.id) {
      return showToast({
        description: t("errors.id.description"),
        type: "error",
        value: t("errors.id.title"),
      });
    }
    try {
      await makePaid({ id: data?.id }).unwrap();
      rest.onClose();
    } catch (error) {}
  }

  return (
    <ModalBase {...rest}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.title}>{t("title")}</h2>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: t("description") }}
          />
          <p className={styles.warning}>
            {t("warning", {
              date: dayjs(data?.expire_at).format("HH:mm DD.MM"),
            })}
            .
          </p>
        </div>
        {error && <Text type="danger">{JSON.stringify(error)}</Text>}
        <Button withAnimation size="lg" loading={isLoading} onClick={onSubmit}>
          {t("submit")}
        </Button>
      </div>
    </ModalBase>
  );
}
