import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {
  IActiveOrderResponse,
  IOrderResponse,
  IOrdersResponse,
} from "./orders.types";
import baseQueryWithTokenWrapper from "../base-query";
import { PER_PAGE } from "../../utils/constants/shared";

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: baseQueryWithTokenWrapper,
  tagTypes: ["order"],
  endpoints: (builder) => ({
    getActiveOrder: builder.query<IActiveOrderResponse, void>({
      query: () => "massmo/v1/payout_orders/active",
      providesTags: ["order"],
      transformResponse: (res: IActiveOrderResponse) => {
        const is_final_state =
          res?.data.state === "completed" ||
          res?.data.state === "canceled" ||
          res?.data.state === "expired";
        const payment_functionality_visible =
          !is_final_state &&
          res?.data.state !== "verification" &&
          res?.data.state !== "manual_verification";
        const fio =
          res?.data.recipient_first_name || res?.data.recipient_last_name
            ? `${res?.data.recipient_first_name || ""} ${
                res?.data.recipient_middle_name || ""
              } ${res?.data.recipient_last_name || ""}`
            : null;

        return {
          ...res,
          data: {
            ...res.data,
            is_final_state,
            payment_functionality_visible,
            fio,
          },
        };
      },
    }),
    goNextOrder: builder.query<IActiveOrderResponse, void>({
      query: () => "massmo/v1/payout_orders/next",
    }),

    getOrder: builder.query<IOrderResponse, { id: string | number }>({
      query: ({ id }) => `massmo/v1/payout_orders/${id}`,
      providesTags: ["order"],
    }),

    getOrders: builder.query<
      IOrdersResponse,
      {
        page?: string | number;
        sort?: string;
        state?: string;
        amount?: string;
        amountFrom?: string;
        amountTo?: string;
        dateTo?: string;
        dateFrom?: string;
      }
    >({
      query: (params) => {
        let query = `massmo/v1/payout_orders?per=${PER_PAGE}`;

        if (params.page) {
          query =
            query +
            `&page=${params.page}&q[sorts]=${
              params.sort ? `${params.sort}` : `created_at DESC`
            }`;
        }
        if (params.state) {
          query = query + `&q[state_eq]=${params.state}`;
        }
        if (params.amount) {
          query = query + `&q[amount_eq]=${params.amount}`;
        }
        if (params.amountFrom) {
          query = query + `&q[amount_gteq]=${params.amountFrom}`;
        }
        if (params.amountTo) {
          query = query + `&q[amount_lteq]=${params.amountTo}`;
        }
        if (params.dateFrom) {
          query =
            query +
            `&q[created_at_gt]=${new Date(+params.dateFrom).toISOString()}`;
        }
        if (params.dateTo) {
          query =
            query +
            `&q[created_at_lt]=${new Date(+params.dateTo).toISOString()}`;
        }
        return query;
      },
      providesTags: ["order"],
    }),
    completeOrder: builder.mutation<
      IActiveOrderResponse,
      { id: string | number; files: File[]; senderBankName?: string }
    >({
      query: (params) => {
        const formdata = new FormData();

        for (const file of params.files) {
          formdata.append('proofs[]', file);
        }


        if (params.senderBankName) {
          formdata.append("sender_bank_name", params.senderBankName);
        }

        return {
          url: `massmo/v1/payout_orders/${params.id}/verification`,
          method: "POST",
          body: formdata,
        };
      },
    }),
    manualVerification: builder.mutation<
      IActiveOrderResponse,
      { id: string | number }
    >({
      query: (params) => {
        return {
          url: `massmo/v1/payout_orders/${params.id}/manual_verification`,
          method: "POST",
        };
      },
      invalidatesTags: ["order"],
    }),
    makePaid: builder.mutation<IActiveOrderResponse, { id: string | number }>({
      query: (params) => {
        return {
          url: `massmo/v1/payout_orders/${params.id}/paid`,
          method: "POST",
        };
      },
      invalidatesTags: ["order"],
    }),
    cancelOrder: builder.mutation<
      IActiveOrderResponse,
      { id: string | number }
    >({
      query: (params) => {
        return {
          url: `massmo/v1/payout_orders/${params.id}/cancel`,
          method: "POST",
        };
      },
      invalidatesTags: ["order"],
    }),
  }),
});

export const {
  useGetOrderQuery,
  useGetActiveOrderQuery,
  useCompleteOrderMutation,
  useCancelOrderMutation,
  useGetOrdersQuery,
  useGoNextOrderQuery,
  useLazyGoNextOrderQuery,
  useMakePaidMutation,
  useManualVerificationMutation,
} = ordersApi;
